import React, { useEffect } from "react";
import { useRouter } from "next/router";

const Home = () => {
  const router = useRouter();

  useEffect(() => {
    router.push("/Dashboard");
  }, []);

  return <></>;
};

Home.layout = "L1";
export default Home;
